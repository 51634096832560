import {
  createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory,
} from 'vue-router'
import FrontPage from '../views/FrontPage.vue'

const routes = [
  {
    path: '/',
    name: 'frontpage',
    component: FrontPage,
  },
]

export function createRouter(isSSR) {
  const baseUrl = '/'
  return _createRouter({
    routes,
    history: isSSR ? createMemoryHistory(baseUrl) : createWebHistory(baseUrl),
  })
}
