import { createApp } from './main'

const init = () => {
  const injectedState = window.INITIAL_DATA

  const appElement = '#app'
  const { app, router } = createApp(injectedState)

  router.isReady().then(() => {
    app.mount(appElement)
  })
}

init()
