import { createSSRApp } from 'vue'

import App from './App.vue'

import { createRouter } from './router/router.js'
import { injectionKeys } from './injectionKeys.js'
import { FluentPlugin } from './i18n/fluent.js'

/* eslint-disable import/no-unresolved */
import english from './i18n/en.ftl?raw'
import norwegian from './i18n/nb_NO.ftl?raw'
import swedish from './i18n/sv.ftl?raw'

const translations = {
  no: norwegian,
  en: english,
  se: swedish,
}

export function createApp(initialProps = {}) {
  const isSSR = initialProps.isSSR || false
  const app = createSSRApp(App, initialProps)

  const fluent = FluentPlugin({
    translations,
    locale: initialProps.topLevelDomain,
  })
  const router = createRouter(isSSR)
  app.use(fluent).use(router)

  app.provide(injectionKeys.fluent, fluent.safeFormat)

  return { app, router }
}
