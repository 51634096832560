
import { FluentBundle, FluentResource } from '@fluent/bundle'
import { createFluentVue } from 'fluent-vue'

const defaultLocale = 'en'

const getTranslation = (translations, locale) => {
  return translations[locale] || translations[defaultLocale]
}

/**
 * A Vue plugin to set up Fluent
 *
 * Installation:
 * app.use(FluentPlugin(options))
 *
 * @param options
 */

export const FluentPlugin = (options) => {
  const { translations, locale } = options
  const translation = getTranslation(translations, locale)

  const translationBundle = new FluentBundle(locale || defaultLocale, {
    // disable unicode isolation characters https://github.com/projectfluent/fluent.js/wiki/Unicode-Isolation
    // it's used for bidi text, which we don't have https://github.com/projectfluent/fluent/wiki/BiDi-in-Fluent
    // in practice it means we don't inject unicode characters that indicates text direction into our strings
    useIsolating: false,
  })
  translationBundle.addResource(new FluentResource(translation))

  const fluent = createFluentVue({
    bundles: [translationBundle],
  })
  fluent.safeFormat = (key, value) =>
    !translationBundle.hasMessage(key) ? undefined : fluent.format(key, value)
  return fluent
}
